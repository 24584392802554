/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";
import Helmet from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";
// import "../templates/posts/LinkBar.css";

function SEO({ description, keywords, lang, meta, title }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            twitter_author
          }
        }
      }
    `
  );

  const metaDescription = description || site.siteMetadata.description;

  return (
    <Helmet
      htmlAttributes={{
        lang
      }}
      title={title || site.siteMetadata.title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        {
          name: `description`,
          content: metaDescription
        },
        // https://www.redclayinteractive.com/what-are-open-graph-tags/
        {
          property: `og:title`,
          content: title
        },
        {
          property: `og:description`,
          content: metaDescription
        },
        //https://developer.twitter.com/en/docs/tweets/optimize-with-cards/overview/markup
        {
          name: `twitter:card`,
          content: `summary`
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.twitter_author || ""
        },
        {
          name: `twitter:title`,
          content: title
        },
        {
          name: `twitter:description`,
          content: metaDescription
        }
      ].concat(meta)}
    >
      {/* <link
        href="//cdn-images.mailchimp.com/embedcode/classic-10_7.css"
        rel="stylesheet"
        type="text/css"
      ></link> */}
      <link
        rel="stylesheet"
        href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap"
      />
      <script type="text/javascript">
        {`_linkedin_partner_id = "1831562"; 
        window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || []; 
        window._linkedin_data_partner_ids.push(_linkedin_partner_id);`}
      </script>
      <script type="text/javascript">
        {`(function() { 
          var s = document.getElementsByTagName("script")[0];
          var b = document.createElement("script"); 
          b.type = "text/javascript";
          b.async = true; 
          b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js"; 
          s.parentNode.insertBefore(b, s);
        })();`}
      </script>
      <noscript>
        {`
        <img
          height="1"
          width="1"
          style="display:none;"
          alt=""
          src="https://px.ads.linkedin.com/collect/?pid=1831562&fmt=gif"
        />`}
      </noscript>
    </Helmet>
  );
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
  keywords: ``
};

export default SEO;
